@import "solarized-light";

code { background-color: #fdf6e3; color: #586e75; padding: 0.4em; border-radius: 5px; }
.highlight { border-radius: 5px; }
.highlight pre { padding: 1em; }
.highlight pre code { padding: 0; }


body {
  background-color : #ffffff;
  font-family : sans-serif;
  color : #000000;
  margin : 0em 0em 3em 0em;
}

:link, :visited {
  text-decoration : none;
  font-weight : bold;
  color : #5ca5ff;
  background-color : transparent;
}

:link:hover, :visited:hover {
  color : #ffcc66;
  background-color : transparent;
}

ins {
  font-style : italic;
  text-decoration : none;
}

/* logo */

div.logo {
  background-color : #cce3ff;
  color : #52a0ff;
  margin : 0em;
  padding : 0em;
}

div.logo h1 {
  letter-spacing : 0.1em;
  margin : 0em;
  padding : 0.8em 0.2em 0.2em 1.0em;
  font-size : 1.7em;
  font-weight : normal;
}

div.logo :link, div.logo :visited {
  color : #3892ff;
  background-color : transparent;
  font-weight : normal;
}

div.logo :link:hover, div.logo :visited:hover {
  color : #3892ff;
  background-color : transparent;
  font-weight : normal;
}


/*navbar*/

div.navbar {
  padding-bottom : 1.2em;
  border-bottom : 1px solid #99bbdd;
  margin-bottom : 0.5em;
  padding-left : 1em;
}

div.navbar ul {
  margin : 0em;
  padding : 0em;
}

div.navbar li {
  display : block;
  float : left;
  border-left : 1px solid #aaccee;
  border-top : 1px solid #aaccee;
  border-right : 1px solid #8899cc;
  border-bottom : 1px solid #8899cc;
  padding : 0.0em;
  margin : 0.3em;
  background-color : #f7fcff;
}

div.navbar :link, div.navbar :visited {
  display : block;
  text-decoration : none;
  background-color : transparent;
  color : #6688cc; /*6688cc 99bbdd*/
  padding : 0.2em 0.6em 0.2em 0.6em;
  margin : 0em;
  font-weight : bold;
}

div.navbar :link:hover, div.navbar :visited:hover {
  background-color : #ffee88;
  color : #5ca5ff;
  font-weight : bold;
}

div.navbar #f:hover {
  background-color : #e5d8fd;
  color : #5ca5ff;
}

div.navbar #e:hover {
  background-color : #ffe8cc;
  color : #5ca5ff;
}

div.navbar #d:hover {
  background-color : #fcffcc;
  color : #5ca5ff;
}

div.navbar #c:hover {
  background-color : #e3ffcc;
  color : #5ca5ff;
}

div.navbar #b:hover {
  background-color : #ccffcf;
  color : #5ca5ff;
}

div.navbar #a:hover {
  background-color : #ffe6e5;
  color : #5ca5ff;
}

/* main content area */

div.main {
  padding : 1.5em 7em 2em 5em;
  line-height : 1.4em;
}

div.main h1 {
  font-weight : normal;
  letter-spacing : 0.2em;
  color : #4466cc;
  font-size : 1.5em;
  border-left : 15px solid #4466cc; /*5ca5ff 99bbdd*/
  border-bottom : 1px solid #4466cc;
  padding-left : 0.5em;
  margin-left : -1.5em;
}


div.main h2 {
  font-weight : bold;
  color : #9c99ff;
  font-size : 1.2em;
  border-left : 15px solid #9c99ff;
  border-bottom : 1px solid #9c99ff;
  padding-left : 0.5em;
  margin-left : -0.5em;
  letter-spacing : 0.1em;
}

.rounded {
  border-radius: 0.375rem;
}
